<template>
  <div>
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{$trans('Daily Reports')}}
      </span>
      <button @click="load_daily_tasks" class="btn btn-primary btn-sm mr-3">
        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
          <line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line>
          <line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        {{ $trans('Update Report') }}
      </button>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
    </h4>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="form-group mr-3">
            <label>{{ $trans('User') }}</label>
            <Select2 class="w-200" v-model="filterQuery.user_id" :options="users"/>
          </div>
        </div>
        <div class="filter-actions d-flex">
          <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
          <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
            <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Apply') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="rows && rows.data" class="table-responsive">
          <div class="list-info mb-3">
            <small>{{$trans('Results')}}: <strong>{{rows.data.length}}</strong> {{$trans('of')}} <strong>{{rows.total}}</strong></small>
          </div>
          <table class="table table-striped mb-3">
            <thead>
            <tr>
              <th>{{$trans('Date')}}</th>
              <th v-if="$can(['view-daily-reports','content-leader','it-leader','design-leader'])">{{$trans('User')}}</th>
              <th>{{$trans('Task')}}</th>
              <th>{{$trans('Result')}}</th>
              <th>{{$trans('Total task')}}</th>
              <th>{{$trans('Note')}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row,index) in rows.data" v-bind:key="index">
              <td>{{row.date}}</td>
              <td v-if="$can(['view-daily-reports','content-leader','it-leader','design-leader'])"> <slot v-if="row.user">{{ row.user.username }}</slot></td>
              <td>
                <span v-for="(item,k) in row.tasks" :key="k" class="d-block">
                  <span v-if="item.status ==1" class="text-success">
                    <svg viewBox="0 0 24 24" width="10" height="10" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                  </span>
                  <span class="text-danger" v-else>
                    <svg viewBox="0 0 24 24" width="10" height="10" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></svg>
                  </span>
                  {{item.task}}
                  <slot v-if="item.url">- <span v-html="$short_link(item.url)" ></span></slot>
                </span>
              </td>
              <td>
                <vue-numeric
                currency-symbol-position="suffix"
                currency="%"
                v-model="row.percent"
                v-bind:read-only="true"
                :minus="true"
                v-bind:precision="0">
                </vue-numeric>
              </td>
              <td>{{row.total_task}}</td>
              <td>
                  <span class="d-block" v-for="(note,i) in row.notes" :key="i">{{note}}</span>
              </td>
              <td class="text-right">
                <slot v-if="$can('add-note-daily-reports')">
                  <button @click="form_note(row)" class="btn btn-xs btn-secondary" title="Add Note">
                    <svg data-v-0acc1ee4="" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-0acc1ee4="" data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-0acc1ee4="" data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
                  </button>
                </slot>
              </td>
            </tr>
            </tbody>
          </table>
          <paginate v-if="rows && rows.data && rows.last_page"
                    :page-count="rows.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
    <modal v-if="addForm" v-bind:width="'800px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Update daily tasks') }}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{ $trans('Detail') }} (*)</label>
          <table class="table-striped table">
            <thead>
            <tr>
              <th>{{ $trans('Task') }}</th>
              <th>{{ $trans('Url') }}</th>
              <th>{{ $trans('Status') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,k) in tasks" :key="k">
              <td>
                <input v-model="tasks[k]['task']" class="form-control" type="text">
              </td>
              <td>
                <input v-model="tasks[k]['url']" class="form-control" type="text">
              </td>
              <td>
                <label>
                  <input v-model="tasks[k]['status']" type="checkbox" true-value="1" false-vaule="0">
                  {{$trans('Done')}}
                </label>
              </td>
              <td class="text-right">
                <a @click="removeDetail(k)" href="javascript:void(0)">
                  {{ $trans('Remove') }}
                </a>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="3"></td>
              <td class="text-right">
                <a @click="addDetail()" href="javascript:void(0)">
                  {{ $trans('Add') }}
                </a>
              </td>
            </tr>
            </tfoot>
          </table>
          <slot v-if="errors && errors['detail']">
            <div class="text-danger" v-for="error in errors['detail']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>

        <button @click="update" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Update') }}
        </button>
      </slot>
    </modal>
    <modal v-if="formNote" v-bind:width="'800px'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Add Note') }}
          <button @click="formNote = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label class="d-block">{{ $trans('Note') }}</label>
          <input type="text" class="form-control" v-model="noteFields.text">
          <slot v-if="errors && errors['text']">
            <div class="text-danger" v-for="error in errors['text']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="add_note(task)" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Add') }}
        </button>
      </slot>
    </modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "DailyReport",
  data:function () {
    return {
      process_loading:false,
      formFilter:false,
      filterQuery:{
        page:1,
        keyword: ''
      },
      rows:null,
      addForm:false,
      daily_task:null,
      alert:null,
      errors:{},
      process:false,
      tasks:[],
      formNote:false,
      task:null,
      noteFields:{}
    }
  },
  methods:{
    index:function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/daily/reports',params:this.filterQuery, method: 'GET'})
          .then(resp => {
            this.rows = resp.data;
            this.process_loading = false
          })
          .catch(err => {
            console.log(err)
          })
    },
    load_daily_tasks:function () {
      this.loading = true
      axios({url: this.$root.$data.api_url + '/api/daily/reports/get',params:{}, method: 'GET'})
          .then(resp => {
            this.daily_task = resp.data;
            this.tasks = resp.data.tasks;
            this.loading = false
            this.addForm = true
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
    },
    reset_filter:function () {
      this.filterQuery ={
        page:1,
        keyword:'',
      }
      this.index()
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    update:function (){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {
          tasks:this.tasks
        }
        axios({url: this.$root.$data.api_url + '/api/daily/reports/update/'+this.daily_task._id, data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.alert = {
                  type: 'success',
                  message: resp.data.message
                }
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    form_note(task){
      this.task = task
      this.formNote = true
    },
    addDetail: function () {
      let item = {
        task: '',
        url: '',
        status: 0,
      }

      let items = this.tasks
      items.push(item)
      this.tasks = items
    },
    removeDetail: function (index) {
      let items = this.tasks
      items.splice(index, 1);

      this.tasks = items
    },
    get_users: function () {
      axios({url: this.$root.$data.api_url + '/api/all_user', params: {roles: []}, method: 'GET'})
          .then(resp => {
            this.response_users = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    add_note:function (task){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.noteFields
        axios({url: this.$root.$data.api_url + '/api/daily/reports/addNote/' + task._id, data:data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.formNote = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
  },
  created() {
    this.index()
    this.get_users()
  },

  computed:{
    users: function () {
      if (!this.response_users) {
        return [];
      }
      let users = [];
      let response_users = this.response_users;
      response_users.forEach((el) => {
        let item = {
          id: el._id,
          text: el.username
        }
        users.push(item)
      })

      return users;
    },
  }
}
</script>



<style scoped>

</style>